.alerts {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 50%;
  width: 100%;
  padding: 0 20px;
  max-width: 700px;
  transform: translateX(-50%);
}
