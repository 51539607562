.trip-actions {
  max-width: 400px;
  margin: 0 auto;

  .aspect-ratio-1-1 {
    button {
      position: absolute;
    }
  }
}
