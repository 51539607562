@import 'bootstrap/scss/bootstrap';
@import 'trip/App.scss';
@import 'trip/AlertProvider.scss';
@import 'trip/TrackList.scss';

.aspect-ratio-1-1 {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  width: 100%;
  display: block;

  > * {
    width: 100%;
    position: absolute;
    height: 100%;
  }
}

// ✌⊂(✰‿✰)つ✌
.logo {
  animation: blurmoped 500s ease;
  animation-fill-mode: forwards;
}

@keyframes blurmoped {
  0% {
    filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    -moz-filter: blur(0px);
    -webkit-filter: blur(0px);
  }
  80% {
    filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    -moz-filter: blur(0px);
    -webkit-filter: blur(0px);
  }
  100% {
    filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    -moz-filter: blur(4px);
    -webkit-filter: blur(4px);
  }
}

.effect-blur {
  transition: all 0.35s;

  filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  -moz-filter: blur(0px);
  -webkit-filter: blur(0px);

  &.effect-active {
    filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    -moz-filter: blur(4px);
    -webkit-filter: blur(4px);
  }
}

.effect-rainbow {
  transition: all 0.35s;
  position: relative;

  &.effect-active {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0);
    border-color: rgb(204, 0, 255);

    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background: linear-gradient(
        124deg,
        #dd00f3,
        #e81d1d,
        #e8b71d,
        #e3e81d,
        #1de840,
        #1ddde8,
        #2b1de8,
        #dd00f3,
        #dd00f3,
        #ff2400,
        #e81d1d,
        #e8b71d,
        #e3e81d,
        #1de840,
        #1ddde8,
        #2b1de8,
        #dd00f3,
        #dd00f3,
        #ff2400,
        #e81d1d,
        #e8b71d,
        #e3e81d,
        #1de840,
        #1ddde8,
        #2b1de8,
        #dd00f3,
        #dd00f3
      );
      background-size: 1800% 1800%;

      -webkit-animation: rainbow 8s ease infinite;
      -z-animation: rainbow 8s ease infinite;
      -o-animation: rainbow 8s ease infinite;
      animation: rainbow 8s ease infinite;
    }
  }
}

@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-moz-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-o-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
