.track-list {
  .track-list-item {
    .track-details {
      display: none;
    }

    input {
      &:checked {
        + .track-details {
          display: block;
        }
      }
    }
  }
}
